.swiper-slide {
  opacity: 0.5;
  display: flex;
}

.swiper-slide-active {
  opacity: 1;
  justify-content: center;
}

.swiper-slide-prev {
  cursor: pointer;
  justify-content: flex-end;
}

.swiper-slide-next {
  cursor: pointer;
  justify-content: flex-start;
}
